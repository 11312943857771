<template>
  <vue-scroll>
    <Spin :spinning="isFetching">
      <div class="blog-dashboard">
        <div class="blog-dashboard__header">
            <div class="blog-dashboard__title">All Articles</div>
            <div class="blog-dashboard__buttons">
              <button class="buttonGreen" type="button" @click="createArticle()">
                <span class="text">Create new article</span>
              </button>
            </div>
        </div>

        <div class="blog-dashboard__subheader">
          <div class="blog-dashboard__subtitle">Search</div>
          <div class="blog-dashboard__filter">
            <div class="blog-dashboard__select">
              <select>
                <option value="title">By headline</option>
              </select>
            </div>
            <div class="blog-dashboard__search">
              <input class="blog-dashboard__search-field" type="text" placeholder="Search news" v-model="terms"/>
            </div>
          </div>
          <div class="blog-dashboard__status">
            <button v-for="elem of statuses" :key="elem.name" :class="{'button buttonBlack blog-dashboard__status-button': true, 'blog-dashboard__status-button--select': elem.value === status}" type="button" @click="changeStatus(elem.value)">
              <span class="text">{{ elem.name }}</span>
            </button>
          </div>
        </div>
          <div class="blog-dashboard__content" v-if="posts.length > 0">
            <div class="blog-dashboard__subtitle">Articles</div>
            <div class="blog-dashboard__list">
              <table>
                <tbody>
                  <ArticlePreview v-for="elem of posts" :key="elem.id" :elem="elem"/>
                </tbody>
              </table>
            </div>
            <div class="blog-dashboard__load-more" v-if="posts.length !== pagination.total">
              <button @click="loadMore">Load more ({{ posts.length }}/{{ pagination.total }})</button>
            </div>
          </div>
        </div>
      </Spin>
    </vue-scroll>
</template>

<script>
  import _ from 'lodash'
  import { Spin } from 'ant-design-vue';
  import ArticlePreview from './ArticlePreview'
  import './ArticleList.styl';

  export default {
    name: 'ArticleList',
    data: () => ({
      terms: '',
      subcategory: null,
      status: 'all',
      statuses: [
        { name: 'all', value: 'all' },
        { name: 'published', value: 'published' },
        { name: 'drafts', value: 'drafts' },
        { name: 'archived', value: 'archived' },
      ],
      page: 1,
    }),
    watch: {
      terms: _.debounce(function (val) {
        this.$store.dispatch('setSearch', val);
        this.$store.dispatch('getAdminArticles', this.filter);
      }, 400),
      '$route' (to, from) {
        this.subcategory = to.query.subcategory;
        this.$store.dispatch('getAdminArticles', this.filter);
      },
    },
    computed: {
      isFetching () {
        return this.$store.state.blogDashboard.isFetching;
      },
      posts () {
        return this.$store.state.blogDashboard.posts || [];
      },
      pagination () {
        return this.$store.state.blogDashboard.pagination || {};
      },
      filter () {
        const filters = {};
        let publicationState = 'preview';

        if (this.terms) {
          filters.title = {
            $containsi: this.terms,
          }
        }

        if (this.subcategory) {
          filters.subcategories = {
            id: {
              $eq: this.subcategory,
            },
          }
        }

        if (this.status === 'published') {
          publicationState = 'live'
        }

        return { filters, publicationState, pagination: { page: this.page, pageSize: 10 } };
      },
    },
    methods: {
      changeStatus (status) {
        this.page = 1;
        this.status = status;
        this.$store.dispatch('getAdminArticles', this.filter);
      },
      createArticle () {
        this.$store.dispatch('createArticle');
      },
      updateFilter () {
        const { subcategory } = this.$route.query

        this.subcategory = subcategory;
      },
      loadMore () {
        this.page += 1;
        this.$store.dispatch('getAdminArticles', this.filter);
      },
    },
    mounted () {
      this.updateFilter();
      this.$store.dispatch('getAdminArticles', this.filter);
    },
    components: {
      ArticlePreview,
      Spin,
    },
  };
</script>
